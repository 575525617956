@import "../../styles/variables";


.forgotPassword .d-flex{
    display: flex;
    align-items: center;
    padding: 0px 100px;
}
.verify-email{
    width: 100%;
    height: 100vh;
    margin-left: 0px;
    margin-right: 0px;
    align-items: center;
    justify-content: space-between;
}

.content{
    width: 70%;
    border: 2px solid $border-orange;
    padding: 40px 20px;
    border-radius: 30px;
    float: right;
    position: relative;
}
.content .user-img{
    position: absolute;
    content: "";
    left: 50%;
    transform:translate(-50%,-70%);
    height: 150px;
    width: 150px;
    border: 2px solid $border-orange;
    background-color:  $background-white;
    border-radius: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}
.user-img span{
    text-align: center;
    font-size:40px;
    font-weight: 600;
    display: flex;
    align-items: center;
} 
.user-content{
    margin-top: 100px;
}

// .title span{
//     font-size: 36px;
//     color: #000;
//     font-weight: 600;
//     line-height: normal;
//     margin-bottom: 20px;
// }
.title{
    margin-bottom: 30px;
}

.form-control{
    background-color: $input-background !important;
    height: 50px;
    border: 0px;
    border-radius: 0px;
    
}
.input-group-text {
    width: 60px;
    background-color: $input-group-background;
    padding: 5px 18px !important;
    border: 0px;
    border-radius: 0px;
}
.input-group-text svg{
    font-size: 30px;
    color: $color-icon;
}
.discription{
    margin-top: 7px;
}

.discription p{
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}
.btn-login button{
    background-color: $button-background ;
    color: $background-white;
    padding-top:15px;
    padding-bottom: 15px;
    padding-left: 80px;
    padding-right: 80px;
    text-transform: uppercase;
    border-radius: 15px;
    border: none;
    margin-top: 80px;
    font-weight: 500;
}
@media (max-width: $md) {
    .image img{
        width:100%;
    }
    .user-content {
        margin-top: 70px;
    }
    .title {
        margin-bottom: 20px;
    }
    .discription p {
        font-size: 18px;
    }
    .btn-login button {
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 10px;
        font-size: 18px;
        margin-top: 50px;
    }
    .user-img span {
        font-size: 25px;
    }
    .content .user-img {
        transform: translate(-50%, -65%);
        height: 125px;
        width: 125px;
    }
    .user-content {
        margin-top: 60px;
    }
    
    .content{
        width: 100%;
        margin-top: 80PX;
        padding: 20px;
    }

}

@media (max-width: $sm) {
    .content{
        width: 100%;
        margin-top: 100PX;
    }
    .verify-email .image img{
        width: 100%;
    }
    .btn-login button{
        margin-top: 30px;
        font-size: 20px;
    }
    .content .user-img{
        transform:translate(-50%,-65%);
        height: 125px;
        width: 125px;
    }
    .user-img span{
        font-size:25px;
    } 
    .content{
        margin-top: 80PX;
    }
    .user-content {
        margin-top: 60px;
    }
    
    .title {
        margin-bottom: 25px;
    }
    .discription p {
        font-size: 16px;
    }
    .content{
        padding: 20px 20px;
    }
    .btn-login button {
        padding-left: 50px;
        padding-right: 50px;
    }
    .forgotPassword .d-flex {
        padding: 0px 50px;
    }
}


@media (max-width: $xs) {
    .forgotPassword .d-flex {
        padding: 0px;
        padding-left: 10px;
    }
    .verify-email .col-12{
        width: 100%;
    }
    .verify-email{
      display: block !important;
    }
    .title {
        margin-bottom: 20px;
    }
    .discription p {
        font-size: 10px !important;
    }
    .btn-login button {
        font-size: 12px !important;
        margin-top: 20px !important;
        padding: 5px 30px !important;
    }
    .content .user-img {
        transform: translate(-50%, -70%);
        height: 100px;
        width: 100px;
    }
    .content {
        margin-top:70px !important;
        padding: 15px;
    }
    .user-content {
        margin-top: 50px;
    }
    .input-group-text {
        width: 40px;
        padding: 5px 8px !important;
    }
    .form-control {
        height: 40px;
    }
    .user-img span {
        font-size: 22px;
    }
}
@media (max-width:575px) and (min-width: 321px){
    .forgotPassword .d-flex {
        padding: 0px;
    }
    .title {
        margin-bottom: 20px;
    }
    .discription p {
        font-size: 13px;
    }
    .btn-login button {
        margin-top: 30px;
        font-size: 16px;
    }
    .input-group-text {
        padding: 0px 8px !important;
    }
    .content {
        margin-top: 60PX;
    }
    .input-group-text {
        width: 45px;
    }
    .content .user-img {
        transform: translate(-50%, -70%);
        height: 100px;
        width: 100px;
    }
   
 }
 @media (max-width:610px) and (min-width:577px){
    // .title span {
    //     font-size: 20px;
    // }
    .forgotPassword .d-flex {
        padding: 0px 50px;
    }
   
 }
 @media (max-width:912px) and (min-width: 767px){
    .forgotPassword .d-flex {
       padding: 0PX 50PX;
    }
    .content {
        width: 100% !important;
        padding: 40px;
        padding: 20px !important;
    }
    .image img{
        width: 100%;
    }
    .content .user-img {
        transform: translate(-50%, -70%) !important;
        height: 125px !important;
        width: 125px !important;
    }
    .user-img span {
        font-size: 25px !important;
    }
    .user-content {
        margin-top: 60px !important;
    }
    .form-control {
        height: 60px;
    }
    .user-content .title{
       margin-bottom: 20px;
    }
    .discription p {
        font-size: 14px !important;
    }
    .btn-login button {
        margin-top: 20px !important;
        font-size: 16px !important;
        padding: 5px 60px !important;
        border-radius: 10px !important;
    }
}
 @media (max-width:1280px) and (min-width: 992px){
    .forgotPassword .d-flex {
        display: flex;
        align-items: center;
        padding: 0px 50px;
    }
    .image img{
        width: 100%;
    };
    .content {
        width: 90%;
        padding: 20px 20px;
    }
    .btn-login button {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 40px;
        font-size: 16px;
    }
    // .title span {
    //     font-size: 26px;
    // }
    .title {
        margin-bottom: 25px;
    }
    .user-img span {
        font-size: 25px;
    }
    .content .user-img {
        transform: translate(-50%, -70%);
        height: 125px;
        width: 125px;
    }
    .user-content {
        margin-top: 60px;
    }
}
@media (max-width:991px) and (min-width: 913px){
    .verify-email .col-md-12 {
        flex: 0 0 auto;
        width: 50%;
    }
    .forgotPassword .d-flex {
        padding: 0px 50px;
    }
    .image img{
        width: 100%;
    };
    .content {
        width: 90%;
        padding: 20px 20px;
    }
    // .title span {
    //     font-size: 22px;
    // }
    .discription p {
        font-size: 14px;
    }
    .btn-login button {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 60px;
        padding-right: 60px;
        border-radius: 10px;
        margin-top: 30px;
        font-size: 16px;
    }
    .title {
        margin-bottom: 25px;
    }
    .user-img span {
        font-size: 25px;
    }
    .user-content {
        margin-top: 70px;
    }
    .content .user-img {
        transform: translate(-50%, -70%);
        height: 125px;
        width: 125px;
    }
}

