@import "../../../views/styles/variables.scss";

.color-picker-input {
    position: relative;
    display: inline-block;
  }
  
  .color-preview {
    width: 30px;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .color-picker-trigger {
    width: 100%;
    border-radius: 10px;
    background: rgba(181, 71, 8, 0.1);
    height: 52px;
    display: flex;
    align-items: center;
    color: $color-white;
    font-size: 24px;
    font-weight: 400;
    margin: 12px 0px 0px 0px;
    justify-content: end;
    gap: 5px;
    cursor: pointer;
    top: 40px; /* Adjust this value to control the distance between the preview and color picker */
    left: 0;
    z-index: 1;
  }