@import "../../../../styles/variables";

.header{
    font-size: calc(2rem - 10px);
    color: $color-text;
}

.overflow_scroll{
    height:calc(100vh - 90px) ;
    overflow: auto;
    overflow-x: hidden;
}
.me-10{
    margin-right: 10rem;
}


.custom-radio-checked input[type=radio]{
    height: 20px;
    width: 20px;
}
.custom-radio-checked .form-check{
    margin-right: 10rem !important;
    display: flex;
    align-items: center;
}
.custom-radio-checked .form-check-label{
    font-size: 1.5rem;
}
.answer-tactical.custom-radio-checked .form-check{
    margin: 0px !important;
}
.custom-select .form-select{
    color: $color-white;
    background-color: $color-primary;
    --bs-form-select-bg-img: url("../../../../../assets/images/arrowDown.png");  
    background-size: 20px 15px;                       
}
.custom-select .form-select:focus {
    border-color: none;
    outline: none;  
    box-shadow: none;
}
.btn-fixed{
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-40% , );
}