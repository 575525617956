@import "../../../styles/variables";
// .btn-delete{
//     padding: 5px 40px !important;
// }
.dropdown{
    background-color: $color-primary !important;
    margin-left: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
}