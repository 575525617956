@import "../../styles/variables";


.verifyOTP .d-flex{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 100px;
}
.verify-otp{
    width: 100%;
    height: 100vh;
    margin-left: 0px;
    margin-right: 0px;
    align-items: center;
    justify-content: space-between;
}
.content{
    width: 70%;
    border: 2px solid $border-orange;
    padding: 40px 20px;
    border-radius: 30px;
    float: right;
    position: relative;
}
.content .user-img{
    position: absolute;
    content: "";
    left: 50%;
    transform:translate(-50%,-70%);
    height: 150px;
    width: 150px;
    border: 2px solid $border-orange;
    background-color:  $background-white;
    border-radius: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}
.user-img span{
    text-align: center;
    font-size:40px;
    font-weight: 600;
    display: flex;
    align-items: center;
} 
.user-content{
    margin-top: 70px;
}

.title span{
    font-size: 28px;
    color:$color-text;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
}
.title{
    margin-bottom: 30px;
}
.otp-input{
    margin: 50px 0px;
}

.discription p{
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
}
.btn-login button{
    background-color: $button-background ;
    color: $background-white;
    padding-top:10px;
    padding-bottom: 10px;
    padding-left: 80px;
    padding-right: 80px;
    text-transform: uppercase;
    border-radius: 15px;
    border: none;
    font-size: 22px;
    margin-top: 50px;
    font-weight: 400;
}
 @media (max-width: $md) {
    .verifyOTP{
        padding: 20px 100px;
    }
    .verifyOTP .d-flex{
        padding: 0px;
    }
    .verify-otp .image img{
        width: 100%;
    }.content .user-img {
        transform: translate(-50%, -65%);
    }
    .content{
        width: 100%;
        margin-top: 100PX;
        padding: 20px;
    }

}

 @media (max-width: $sm) {
    .verifyOTP{
        padding: 0px;
    }
    .content .user-img {
        transform: translate(-50%, -65%);
        height: 100px;
        width: 100px;
    }
    .user-img span {
        font-size: 25px;
    }
    .user-content {
        margin-top: 50px;
    }
    .content{
        width: 100%;
        margin-top: 60PX;
        padding: 15px;
    }
    .title span {
        font-size: 16px;
    }
    .otp-input {
        margin: 20px 0px;
    }
    .otp-input input{
       height: 40px;
       width: 40px;
    }
    
 }


@media (max-width: $xs) {
    .verifyOTP{
        padding: 10px;
    }
    .content{
        margin-top: 0px
    };                                                
    .otp-input input {
        height: 35px !important;
        width: 35px !important;
        justify-content: center;
    }  
    .title {
        margin-bottom: 15px;
    }    
    .otp-input {
        margin: 15px 0px;
    }   
    .discription p {
        font-size: 14px;
    }    
    .btn-login button {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 55px;
        padding-right: 55px;
        border-radius: 10px;
        font-size: 18px;
        margin-top: 20px;
    }                                        
    
 }
 @media (max-width: 1023px) and (min-width: 820px){
    .content{
        width: 100%;
        margin-top: 100px;
    }
 }

    @media (max-width: 400px) and (min-width: 320px){
        .otp-input input {
            height: 45px !important;
            width: 45px !important;
        }  
}
@media (max-width: 912px) and (min-width: 820px){
    .title span {
        font-size: 42px;
    }
    .content {
        padding: 40px;
    }
    .otp-input input {
        height: 80px !important;
        width: 80px !important;
    }  
    .otp-input div{
        display: flex;
        justify-content: space-around !important;
    }
    .discription p {
        font-size: 30px;
    }
    .btn-login button {
        font-size: 36px;
        padding-left: 100px;
        padding-right: 100px;
    }
}
@media (max-width: 1280px) and (min-width: 1024px){
    .image img{
        width: 100%;
    }
    .content {
        width: 90%;
    }
    .user-img span {
        font-size: 25px;
    }
    .content .user-img {
        transform: translate(-50%, -70%);
        height: 125px;
        width: 125px;
    }
    .user-content {
        margin-top: 60px;
    }
    .title span {
        font-size: 24px;
    }
    .otp-input {
        margin: 30px 0px;
    }
    .otp-input input{
        height: 50px !important;
        width: 50px !important;
    }
    .otp-input div{
        justify-content: space-around !important;
    }
    .discription p {
        font-size: 16px;
    }
    .btn-login button {
        font-size: 20px;
        margin-top: 30px;
    }
    .verifyOTP .d-flex {
        padding: 0px 50px;
    }
}
@media (min-width: 1441px) {
    .otp-input input{
        width: 73px !important;
        height: 73px !important;
    }
    .otp-input div{
        justify-content: space-around !important;
    }
    .image img {
        width: 80% !important;
    }
    
}