@import "../../views/styles/variables";

.delete-modal .bin-icon{
    position: absolute ;
    background-color: $background-white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    height: 125px;
    width: 125px;
    left: 50%;
    transform:translate(-50%,-80%);
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: 100%;
    z-index: 9999;
  }

  .delete-modal .bin-icon img{
    width: 70px;
    text-align: center;
    display: flex;
    align-items: center;
  }
  .custom-delete-modal .modal-content{
    border: none !important;
    border-radius: 20px !important;
  }
  .custom-delete-modal .modal-content .btn-save {
    background-color: #1C8A34;
    padding: 5px 40px;
    font-size: calc(2rem - 10px);
}
.btn-delete{
  padding: 5px 40px !important;
}
