@import "../../../views/styles/variables.scss";

.search_file_input {
    width: 100%;
    border-radius: 10px;
    background: rgba(181, 71, 8, 0.1);
    height: 52px;
    display: flex;
    align-items: center;
    color: $color-white;
    font-size: 24px;
    font-weight: 400;
    cursor: pointer;
    div:first-child {
        text-align: start !important;
        width: 80%;
        color: $color-text;
        background: $light-background;
        padding: 0px 0px 0px 20px;
    }
    div:last-child {
        background-color: #D9D9D9;
        height: 100%;
        width: 20%;
        padding: 0px 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
    }
}
