@import "../../../styles/variables";


.css-5wly58-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
    justify-content: center !important;
}
.css-5wly58-MuiDataGrid-root .MuiDataGrid-iconSeparator {
    display: none;
}
.header{
    font-size: calc(1.5rem - 5px);
    color: $label-color;
}
.MuiDataGrid-root{
    border: none !important;
}
.MuiDataGrid-columnHeaders{
    border-top: 1px solid $color-text !important;
    border-bottom: 1px solid $label-color !important;
    border-radius: 0px !important;
}
.fs-item{
    width:100% !important;
    display: flex;
    justify-content: center;
    font-size: calc(1.5rem - 5px);
    color: $color-text;
}

.create-edit-acconut .modal-content {
    border:2px solid $color-primary !important;
    border-radius: 25px !important;
    box-shadow: 0px 0px 6px 5px rgba(0, 0, 0, 0.15);
}
    .modal-content .user-form .frm-input .form-label{
    font-size: calc(2rem - 15px);
    color: $color-text;
}
.form-control:focus {
    outline: none !important;       
    box-shadow: none !important;
}
 
.modal-content .user-form{
    margin-top: 0px !important;
}
 .modal-content .user-form ::placeholder {
    font-weight: 600;
    color: $color-text !important;
}

 .modal-content .user-form .frm-input{
    margin-bottom: 6px !important;
}
.modal-content .btn-save{
    background-color: $color-success;
    font-size: calc(2rem - 10px);
}   

.modal-content .btn-import .button{
    background-color:  $color-primary;
    border: none;
    color: $color-white;
    font-size:  calc(2rem - 10px);
    padding: 5px 10px;
    font-weight: 600;
    letter-spacing: 0.5px;
    width: 30% !important;
    border-radius: 10px;
    margin: 20px 0px;
}
.modal-content .btn-import a{
    font-size: 1.5rem;
    color: $color-text;
}
.modal-content .error-msg{
    margin-top: 5px;
}
.error-msg span {
    padding:0px;
    background-color:0px;
}
.modal-content .form-switch .form-check-input {
    width: 5em;
    height: 2em;
}
.modal-content .form-check-input:checked {
    background-color: $color-success !important;
    border-color:$color-success !important;
}
.modal-content .form-check-input:focus {
    border-color: $color-success !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(25,135,84,.25) !important;
}
.update-password .btn-default{
    height:50px;
    width: 100%;
    font-size: 16px;
    padding: 0px;
    background-color:$button-background ;
    border: 0px;
    border-radius: 10px;
    color: $color-white;
}

.devider-border{
    border-top:1px dashed $color-primary;
    width: 70%;
    margin: 0 auto;
    border-width: 2px;

}

.edit-modal-btn .btn-save{
    padding: 5px 40px;
    margin: 0px !important;
}


.modal-content{
    border:2px solid $color-primary !important;
    border-radius: 25px !important;
    box-shadow: 0px 0px 6px 5px rgba(0, 0, 0, 0.15);
  }
@media (max-width: $xs) {
   
    .modal-content .btn-save {
        margin: 0px !important;
        margin-bottom: 10px !important;
    }
   
    .modal-content .btn-import a {
        text-decoration: none;
        font-size: 18px;
    }
    .update-password .col-8 ,.update-password .col-4{
        width: 50%;
    }
    .update-password .btn-default{
        font-size: 12px;
    }
    .update-password{
        margin-top: 10px !important;
    }
    .edit-modal-btn .btn-delete{
        padding: 5px 25px;
        font-size: 14px;
    }
}

@media (max-width:400px) and (min-width: 321px){
    .update-password .col-8 ,.update-password .col-4{
        width: 50%;
    }
    .update-password .btn-default{
        font-size: 12px;
    }
    .update-password{
        margin-top: 10px !important;
    }
    .edit-modal-btn .btn-delete{
        padding: 5px 25px;
        font-size: 14px;
    }
    
    .modal-content .btn-save{
        margin: 0px !important;
        margin-bottom: 10px !important;
    }
    .edit-modal-btn .btn-save {
        padding: 4px 30px;
        margin: 0px !important;
        font-size: calc(2rem - 10px);
        border-radius: 10px;
    }
}