@import "../../../views/styles/variables";

.data-notfound{
    width: 50%;
    height: auto    ;
    border: 2px solid #EBD6D6;
    border-radius: 20px;
    box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.10);
    text-align: center;
    margin: 10rem auto;
    // margin: 15rem 18rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.text-notfound{
    font-size: calc(3rem - 15px);
    font-weight: 400;
    color: $label-color;
  }



  @media (max-width: $sm){
    .data-notfound{
    width: 80%;
    
}.text-notfound{
    font-size:calc(2rem - 7px);
    font-weight: 400;
    color: $label-color;
  }
  }