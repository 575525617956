@import "../../views/styles/variables" ;

@media (max-width: $xl){

    .menu-item .item-text span {
        font-size: 1rem !important;
        font-weight: 600 !important;
    }
    .menu-item .icon-svg img {
        height: 20px!important;
        width: 20px!important;
    }
  }