@import "../../styles/variables";

.login{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../../assets/images/background.svg");
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: auto;
    background-attachment: fixed;   
    padding: 0% 10%;
}
.user-login{
    width: 35% !important;
    margin-left: auto;
    padding: 35px 30px;
    border: 2px solid $border-white;
    border-radius: 30px;
    position: relative;
}
.user-img span{
    text-align: center;
    font-size:40px;
    font-weight: 600;
    display: flex;
    align-items: center;
} 
.user-login .user-img{
    position: absolute;
    content: "";
    left: 50%;
    transform:translate(-50%,-70%);
    height: 150px;
    width: 150px;
    border: 2px solid $border-white;
    background-color:  $background-white;
    border-radius: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}
.user-form{
    margin-top: 100px;
}
.form-control{
    background-color: #ccc !important;
    height: 50px;
    border-radius: 0px
}
.input-group {
    margin-bottom: 10px;
}
.frm-input{
    margin-bottom: 20px;
}

.input-group-text {
    width: 60px;
    background-color: #E6E6E6;
    padding: 0px 15px !important;
    border-radius: 0px;
}
.input-group-text svg{
    font-size: 30px;
    color: $color-icon;
}
.user-login .btn-login button{
    width: 100%;
    background-color: $background-white;
    padding: 15px 0px;
    font-size: 20px;
    color: $color-text;
    text-transform: uppercase;
    border-radius: 0px;
    border: none;
    margin-top: 60px;
    font-weight: 500;
    letter-spacing: 0.5px;
}
@media (max-width: $md) {
    .login{
        padding: 0% 5%;
    }
    .user-login{
        width: 60% !important;
    }
}

@media (max-width: $sm) {
    .user-login{
        width: 100% !important;
      
    }
    .user-login .btn-login button {
        padding: 10px;
        border-radius: 10px;
    }
}
@media (max-width: $xs) {
    .user-login{
        width: 100%;
        padding: 15px;
    }
    .login{
        padding: 0% 3%;
    }
   .user-login .btn-login button{
        margin-top: 30px;
   }
   .user-form {
        margin-top: 80px;
    }
    .user-login .btn-login button {
        padding: 10px;
        border-radius: 10px;
    }
    .login{
        padding: 0% 5%;
    }
    .user-login .user-img {
        height: 125px;
        width: 125px;
        transform: translate(-50%, -60%);;
    }
    .user-img span{
        font-size: 32px;
    }
    .form-control{
        height: 45px;
    }
    .input-group-text {
        width: 50px;
    }
    
}
@media (max-width:575px) and (min-width: 321px){
    .user-login .user-img {
        transform: translate(-50%, -80%);
        height: 125px;
        width: 125px;
    }
    .user-img span {
        font-size: 25px;
    }
    .user-form {
        margin-top: 70px;
    }
    .user-login .btn-login button {
        margin-top: 50px;
    }
    .form-control{
        height: 45px;
    }
    .input-group-text {
        width: 50px;
    }
}
@media (max-width:912px) and (min-width: 577px){
     .user-login {
        width: 100% !important;
    }
    .login {
        padding: 0% 10%;
    }
    .form-control {
        height: 60px;
    }
    .input-group-text svg {
        font-size: 40px;
    }
    .input-group-text {
        width: 70px;    
    }
    .cursor-pointer p{
        font-size: 22px !important;
    }
    .user-login .btn-login button {
        font-size: 28px;
    }
    
}

@media (max-width:1280px) and (min-width: 912px){
    .user-login {
        width: 50% !important;
    }
  
    .user-login .user-img {
        transform: translate(-50%, -70%);
        height: 125px;
        width: 125px;
    }
    .user-img span {
        font-size: 25px;
    }
    .user-form {
        margin-top: 80px;
    }
    .user-login .btn-login button {
        margin-top: 40px;
        padding: 10px;
    }
    .frm-input{
        margin-bottom: 15px;
    }
}

