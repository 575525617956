// @import "~bootstrap/scss/bootstrap";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
// @import "reset.css";
@import "_variables";
@import "_mixins";
@import "_helpers";
@import "_elements";
@import "_typography";
@import "_forms";
@import "_utils";

html {
  box-sizing: border-box;
  font-size: 62.5%;

  @media (min-width: $xs) {
    font-size: 75%;
  }

  @media (min-width: $lg) {
    font-size: 75%;
  }

  @media (min-width: (($lg + $xl) / 2)) {
    font-size: 80%;
  }

  @media (min-width: $xl) {
    font-size: 90%;
  }

  @media (min-width: $xxl) {
    font-size: 100%;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  min-height: 100vh;
  color: $color-text;
  font-family: $ff-base;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}

*::selection {
  background-color: $color-accent;
  color: $color-text-invert;
}
ul , ol{
  margin: 0px !important;
  padding: 0px !important;
}
ul li{
  list-style: none;
}
.sidebar{
  background-color: $background-primary;
  
}
.side-menu li.active {
  background-color: #F1E1D7; 
  color: #000; 
}
.logo{
 height: 150px;
 width: 150px;
 background-color: $background-white;
 border-radius: 100%; 
 text-align: center;
 display: flex;
 align-items: center;
 justify-content: center;
}
.side-menu .list-item{
  background-color: $background-white;
  padding: 5px 15px;
  border-radius: 15px;
  cursor: pointer;
}
.navbar{
  padding-right: 0px !important;
  border-bottom:  2px dashed $color-primary;
}
.menu-item .item-text span{                 
  font-size:calc(1.5rem - 5px);
}
.img-thumbnail {
  border: 4px solid $background-primary !important;
}
.search-input{
    padding: 10px 20px;
    background-color: $light-background;
    border-radius: 30px;
}
.search-bar .search-input input{
  background-color: $light-background;
  width: 100%;
}
.search-bar .search-input , .search-bar .search-input input::placeholder {
  color: $color-primary;
}
.admin-profile{
  width: 100%;
}
.admin-profile span{
  font-size: 1.5rem;
  font-weight: 400;
  color: $color-primary;
}


.delete-modal .bin-delete{
  padding: 10px 30px;
  font-size: 18px;
  color: $color-white;
  font-weight: 600;
  background-color: $color-danger;
  border: none;
}
.delete-modal .bin-cancel{
  padding: 10px 30px;
  font-size: 18px;
  color: $color-white;
  font-weight: 600;
  background-color: $color-success;
  border: none;
}
.btn-custom {
  padding: 9px 51px;
  color: $color-white;
  font-weight: 400;
  font-size: calc(2rem - 10px);
  text-transform: capitalize;
  background-color: $background-primary;
  border: 0px;
  border-radius: 10px;
}
.btn-light{
  padding: 9px 51px;
  color: $color-primary;
  font-weight: 400;
  font-size: calc(2rem - 10px);
  text-transform: capitalize;
  background-color:$light-background;
  border: 0px;
  border-radius: 10px;
}
.left-rect{
  width: 25px;
  height: 52px;
  border-radius: 10px 0px 0px 10px;
  background: $background-primary;
}

.right-rect{
  width: 55px;
  height: 52px;
  border-radius: 0px 10px 10px 0px;
  background: $background-primary;
  text-align: center;
  padding: 8px;
  cursor: pointer;
}
.text-fs{
  font-size : calc(2rem - 10px) !important;
  font-weight: 400;
  text-transform: capitalize;
  color: $color-text;
  letter-spacing: 1px;
  cursor: pointer;
}
.title-text{
  font-size: 2rem;
  font-weight: 600;
  text-transform: capitalize;
  color: $color-text;
}
.item-list{
  background-color: $light-background;
  border-radius: 10px;
}
.btn-add {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.btn-save{
  padding: 5px 50px;
  color: $color-white;
  font-weight: 400;
  font-size: calc(2rem - 10px);
  text-transform: capitalize;
  background-color: $color-success;
  border: 0px;
  border-radius: 10px;
}
.btn-cancel{
  padding: 5px 50px;
  color: $label-color;
  font-weight: 400;
  font-size: calc(2rem - 10px);
  text-transform: capitalize;
  background-color: transparent;
  border: 2px solid $label-color;
  border-radius: 10px;

}
.btn-delete{
  padding: 5px 40px;
  color: $color-white;
  font-weight: 400;
  font-size: calc(2rem - 10px);
  text-transform: capitalize;
  background-color: $color-danger;
  border: 0px;
  border-radius: 10px;
}
.btn-import{
  height: 40px !important;
  background-color: #FBFBFB !important;
  box-shadow: 0px 0px 3px 1.5px rgba(0, 0, 0, 0.15) !important;
  padding: 10px 40px !important;
  border: none !important;
  border-radius: 5px;
  font-size: calc(1.5rem - 6px);
  font-weight: 500;
}
.btn-update{
  padding: 5px 50px;
  color: $color-white;
  font-weight: 400;
  font-size: calc(2rem - 10px);
  text-transform: capitalize;
  background-color: $background-primary;
  border: 0px;
  border-radius: 10px;
}
.custom-delete-modal .modal-content{
  border:0px !important;
  border-radius: 25px !important;
  box-shadow: 0px 0px 6px 5px rgba(0, 0, 0, 0.15);
}
.modal-content{
  border:2px solid $color-primary !important;
  border-radius: 25px !important;
  box-shadow: 0px 0px 6px 5px rgba(0, 0, 0, 0.15);
}

.custom-input{
  width: 100% !important;
  height: 52px !important;
  border-radius: 10px !important;
  background-color: $light-background !important;
  border: none !important;
}
.custom-textarea{
  width: 100% !important;
  border-radius: 10px !important;
  background-color: $light-background !important;
  border: none !important;
}
.text-breadcrumb{
  font-size: calc(2rem - 10px) !important;
  font-weight: 400;
  text-transform: capitalize;
  color: $label-color !important;
  text-decoration: none;
  cursor: pointer;
}
.hr-bottom{
  border-bottom: 2px solid $color-primary;
}

.custom-frmlabel{
  font-size: calc(1.5rem - 2px) !important;
  font-weight: bold;
}
.custom-grouptext{
  background-color: $background-primary !important;
  border-radius: 0px 10px 10px 0px !important;
  padding: 0px !important;
}
.custom-ans-input{
  flex-wrap: nowrap !important;
}
.index-input{
  height: 52px !important;
  padding:10px !important;
}
.custom-ans-input .ans-input{
  width: 90% !important;
}
.custom-ans-input input[type="number"] {
  text-align: center !important;
  color: $color-white;
  font-weight: 500;
}
.add-priority .form-check-reverse{
  display: flex;
  align-items: center;
}
.add-priority .form-check-reverse .form-check-input
,.custom-check-input .form-check-input{
  float: left !important;
  margin-right: 10px !important;
  height: 20px;
  width: 20px;
}
.customAdd .add{
  background-color: $color-bg-add !important;
  padding: 10px !important;
  height: 70px !important;
  width: 70px !important;
  border-radius: 100% !important;
}
.customAdd{
  position: fixed !important;
  bottom: 30px !important;
  right: 30px !important;
}
.custom-grouptext img{
  height: 40px;
  width: 40px;
  padding: 0px;
  margin: 0 auto;
}
.custom-frm-control{
  background-color: $light-background !important;
  border: none !important;
  height: 52px !important;
  border-radius: 10px 0px 0px 10px !important;
}

.customSwitch .form-check-input{
      margin-left: 10px !important;
      width:3rem !important;
      height: 20px !important;
}
.customSwitch .form-check-input:checked{
      background-color: #1FB42E;
      border-color: #1FB42E;
}
.dataList-table{
  height: 600px !important;
  overflow-y: auto !important;
}
.dataList{
  height: calc(100vh - 220px) !important;
  overflow-y: auto !important;
}
.dataList .action-icon .ico-edit{
  font-size: 1.5rem;
  color:$color-primary ;
}
.dataList .action-icon .ico-arrow{
  font-size: 1.5rem   ;
  color:$color-primary ;

}
.customSwitch .form-check-input:focus {
  border-color: #1FB42E;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(31,180,46,.25);
}
.custom-add-icon{
  height: 42px;
  width: 44px;
  background-color: $color-primary;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.MuiDataGrid-cell--withRenderer{
  display: flex !important;
  justify-content: center !important;
}
.custom-functionKey {
  font-size: calc(1.5rem - 4px) !important;
  font-weight: 400;
  color: $color-text;
  background-color: #F4E9E3;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(181, 71, 8, 0.30);
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.selected-functionKey {
  background-color: $background-primary;
}
.custom-deleteIcon {
  position: absolute;
  display: flex;
  z-index: 1;
  top: 0;
  right: 0;
  cursor: pointer;
}
.select_photo{
  width: 150px;
  height: 150px;
  border: 1px solid $color-text;
  border-radius: 50%;
  overflow:hidden;
  position: relative;
}
.logo-image{
  width: 100%;
  height: 100%;
}
.selected_photo{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.logo-image {
  cursor: pointer;
}
.col-bread-crumbs{
  display:flex;
  align-items: center;
  justify-content: space-between;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

.tactical_game_audio .audio-input.disabled {
  background-color: rgb(181, 71, 8 ,0.5);
}

.tactical_game_image.disabled {
  border-color: rgba(181, 71, 8, 0.5); 
}
.tactical_game_image .disabled-image {
  color: rgba(153, 153, 153, 0.5) !important; 
}
.select-answer{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-slider{
  display: flex;
  align-items: center;
}

.user-profile {
  width: 229px;
  height: 255px;
  background: #f5f5f5;
  border-radius: 10px;
}
.profile-image{
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #828282 !important;
    width: 100%;
    height: 100%;
}
.add-photo-icon{
  height: 50px;
  width: 50px;
}
.bottom-fixed{
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-40%);
  margin: 20px 0px;
}
.admin-profile .profile-img{
  height: 40px;
  width: 40px;
  border: 2px solid $color-primary;
  object-fit: cover;
}
.admin-profile .user-svg{
  color: $color-primary;
}
.profile-button{
  display: flex;
  align-items: center;
  justify-content: center;
}
.MuiDataGrid-cell--withRenderer .fs-item span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px; /* adjust the width as needed */
  display: inline-block;
  text-align: center;
}
.form-check-reverse .form-check-input{
  margin: 0px !important;
}
.form-check-label{
  margin-right: 10px;
}
.form-switch.form-check-reverse{
  padding-right: 0px !important;
}
.tactical_game_image {
  width: 60px;
  height: 60px;
  border: 1px solid $color-primary;
  border-radius: 5px 0px 0px 5px;
  overflow: hidden;
  position: relative;
}
.tactical_game_image .selected_photo {
  width: 100%;
  height: 100%;
  position: relative;
}
.tactical_game_image .selected_photo img.game-image{
  width:60px;
  height:60px;
  object-fit: cover;
}
.select-audio-image{
  background-color: $light-background ;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
}
.tactical_game_image .icon-delete{
  position: absolute;
  top:0;
  right: 0;
}
.audio-input{
    padding: 6px 20px;
    color: $color-white;
    font-weight: 400;
    font-size: calc(1rem);
    text-transform: capitalize;
    background-color: $background-primary;
    border: 0px;
    border-radius: 10px;
}

.tactical_game_image .custom-delete-button{
  position: absolute;
  top:0;
  right: 0;
  height: 25px;
  width: 25px;
  background-color: $color-primary;
  color: $color-white;
  border-radius: 0px 0px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

}
.tactical_game_image .custom-delete-button svg{
  height: 15px;
  width: 15px;
  
}
.tactical_game_audio audio::-webkit-media-controls-play-button,
.tactical_game_audio audio::-webkit-media-controls-panel {
background-color:$light-background ;
border-radius: 0%;
width: 100% ;
}
.tactical_game_audio audio::-webkit-media-controls-play-button{
  height: 35px;
  width: 40px;
  border-radius: 50%;
  background-color: $background-primary;
  margin-right: 10px;
}


.tactical_game_audio .custom-delete-button {
  height: 35px;
  width: 35px;
  background-color: $background-primary;
  border-radius: 50%;
  color: $color-white;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tactical_game_audio .custom-delete-button svg{
  height: 20px;
  width: 20px;
}
.tactical_game_audio  audio::-webkit-media-controls-current-time-display {
  color: $background-primary;
  font-weight:bold;
}
.organization-login-detail{
  height: 47px;
  border: 1px solid #FBFBFB;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 1px rgb(0,0,0 ,0.15);

}
.organization-login-detail p{
  font-size: 22px;
  font-weight: 500;
  color: #000;
}
.organization-login-detail p span{
  font-size: 22px;
  font-weight: 400;
  color: #000;
}
.modal-content .add-import .form-check-input{
  background-color: transparent !important;
  height: 1.5rem !important;
  width:1.5rem !important ;
  border-color: #fff !important;
}

.modal-content .add-import .form-check-input:checked{
  background-color: transparent !important;
  border-color: #fff !important;
  box-shadow: none !important;
}
.import-dataList .import-item{
height: 500px;
overflow-y: auto;
overflow-x: hidden;
}
.check-border-color .form-check-input{
  border-color: #8c8d8d !important;
}

.icon-sync{
  font-size: 1.5rem;
  color: #D08900;
}
.icon-view{
  font-size:1.5rem;
  color: $color-primary;
}
.modal-fullscreen .modal-content {
  height: 100% !important;
  border: 0 !important;
  border-radius: 0 !important;
}
.view-custom-input{
  width: 100% !important;
  height: 52px !important;
  border-radius: 10px !important;
  background-color: $light-background !important;
  border: none !important;
  padding: 10px 20px!important;
  display: flex;
  align-items: center;
}
.view-custom-input p{
  font-size:calc(1.5rem - 4px);
  margin-bottom: 0px !important;
}
.view-custom-input span.targate-audience{
  background-color: #e9e3e3;
  padding: 5px 10px;
  border-radius: 5px;
}
.view-custom-input  .index-input {
  height: 52px !important;
  /* padding: 10px !important; */
  width: 50px;
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: center;
}

.btn-custom-slider{
  padding: 6px 12px;
  color: $color-white;
  font-weight: 400;
  font-size: calc(1.2rem);
  text-transform: capitalize;
  background-color: $background-primary;
  border: 0px;
  border-radius: 8px;

}

.view-modal .modal-content .form-check-input:checked {
  background-color: #0d6efd   !important;
  border-color: #0d6efd   !important;
}
.view-modal .modal-content .form-switch .form-check-input {
  width: 3em !important;
  height: 1.5em !important;
}

.view-modal .modal-content .form-switch .form-check-input:checked {
  background-color: #1FB42E   !important;
  border-color: #1FB42E   !important;
}
.view-modal .modal-content .form-switch .custom-frmlabel{
  font-size: 1rem !important;
}

.custom-component{
  height: 80px;
}


@media (max-width: $lg){
  .offcanvas-lg {
      background-color: $background-primary !important;
      max-width: 70%;
  }
  .offcanvas-backdrop.fade {
    width: 100%;
    height: 105vh;
  }
  .admin-profile{
    width: 8%;
  }
 
  .file_input{
    font-size: 14px !important;
  }
  .css-78d1bb .MuiDataGrid-columnHeaders .MuiDataGrid-columnSeparator{
    visibility: hidden;
  }
  
}
@media (max-width: $md){

  
}
@media (max-width: $sm){
  .admin-profile .profile-img{
  
    margin-right: 0px !important;
  }
  .navbar{
    padding-right: 0px !important;
    border-bottom:  2px dashed $color-primary;
  }
  .search-bar .search-content{
    justify-content: end !important;
  }
  .search-bar .search-content .icon-bell{
    display: block !important;
  }
  .search-bar .search-content .search-input{
    width: 70% !important;
  }
  .offcanvas-lg.offcanvas-start {
   width: 70% !important;
  }
  .logo {
    height: 100px;
    width: 100px;
}
.btn-custom , .btn-light{
  padding: 10px 15px !important;
}
.text-breadcrumb{
  font-size: calc(2rem - 15px) !important;
}
.select-answer{
  display: block !important;
}
.select-slider{
  display: block !important;
}
.col-bread-crumbs{
  display: block !important;
}
.customSwitch .form-check-input{
  margin-top: 0px !important;
}
.profile-button{
  display: block !important;
}
.profile-button div{
  margin: 10px 0px !important;
  text-align: center !important;
}
.bottom-fixed{
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  transform: translateX(0) !important;
  margin: 10px 0px !important;
}
.organization-login-detail p{
  font-size: 18px !important;
}
.organization-login-detail p span{
  font-size: 18px !important;
}.organization-login-detail{
height: auto !important;
}

}


@media (max-width: $xs){
  .admin-profile .profile-img{
    margin-right: 0px !important;
  }
  .navbar{
    padding-right: 0px !important;
  }
  .search-bar .search-content{
    justify-content: end !important;
  }
  .search-bar .search-content .icon-bell{
    display: block !important;
  }
  .search-bar .search-content .search-input{
    width: 70% !important;
  }
  .offcanvas-lg.offcanvas-start {
    width: 70% !important;
  }
  .logo {
    height: 100px;
    width: 100px;
  }
  .btn-custom ,  .btn-light{
    padding: 10px 15px !important;
  }
  .profile-button{
    display: block !important;
  }
  .profile-button div{
    margin: 10px 0px !important;
    text-align: center !important;
  }
  .bottom-fixed{
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    transform: translateX(0) !important;
    margin: 10px 0px !important;
  }
  
}


