@import "../../styles/variables";


.resetPassword .d-flex{
    display: flex;
    align-items: center;
    padding: 0px 100px;
}
.new-password{
    width: 100%;
    height: 100vh;
    margin-left: 0px;
    margin-right: 0px;
    align-items: center;
    justify-content: space-between;
}
.image img{
    width: 100%;
}
.content{
    width: 70%;
    border: 2px solid $border-orange;
    padding: 40px 20px;
    border-radius: 30px;
    float: right;
    position: relative;
}
.content .user-img{
    position: absolute;
    content: "";
    left: 50%;
    transform:translate(-50%,-70%);
    height: 150px;
    width: 150px;
    border: 2px solid $border-orange;
    background-color:  $background-white;
    border-radius: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}
.user-img span{
    text-align: center;
    font-size:40px;
    font-weight: 600;
    display: flex;
    align-items: center;
} 
.user-content{
    margin-top: 80px;
}

.title span{
    font-size: 25px;
    color: $color-text;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
}
.title{
    margin-bottom: 15px;
}

.form-control{
    background-color: $input-background !important;
    height: 50px;
    border: 0px;
    border-radius: 0px;
    
}
.input-group{
    margin-bottom: 20px;
}
.input-group-text {
    width: 60px;
    background-color: $input-group-background;
    padding: 5px 18px !important;
    border: 0px;
    border-radius: 0px;
}
.input-group-text svg{
    font-size: 30px;
    color: $color-icon;
}

.btn-login button{
    background-color: $button-background ;
    color: $background-white;
    padding-top:15px;
    padding-bottom: 15px;
    padding-left: 100px;
    padding-right: 100px;
    text-transform: uppercase;
    border-radius: 15px;
    border: none;
    margin-top: 50px;
    font-weight: 500;
    font-size: 20px;
}
@media (max-width: 1023px) and (min-width: 820px){
    .content{
        width: 100%;
    }
}

@media (max-width: $md) {
    .content {
        width: 100%;
        float: none;
        margin-top: 100px;
        padding: 20px 40px;
    }
    .content .user-img {
        transform: translate(-50%, -65%);
    }
    .resetPassword .d-flex{
        padding: 0px 50px;
    }
    .otp-input div{
        justify-content: space-around;
    }
    .otp-input {
        margin: 30px 0px !important;
    }

}

@media (max-width: $sm) {
    
    .user-img span{
        font-size:25px;
    } 
    .content {
        margin-top: 60px;
    }
    .btn-login button {
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 90px;
        padding-right: 90px;
        border-radius: 10px;
        margin-top: 30px;
        font-size: 18px;
    }
    .input-group{
        margin-bottom: 15px;
    }
    .user-content {
        margin-top: 60px;
    }
    .content .user-img {
        transform: translate(-50%, -70%);
        height: 100px;
        width: 100px;
    }
    .title span{
        font-size: 20px;
    }
   
    .discription p {
        font-size: 16px;
    }
    .content{
        padding: 20px 20px;
    }
   
}

 @media (max-width: $xs) {
    .resetPassword .d-flex {
        padding: 0px;
    }
    .user-content {
        margin-top: 55px;
    }
    .content {
        margin-top: 0PX;
        padding: 15px 15px;
    }
    .content .user-img{
       
        transform: translate(-50%, -65%);
        height: 100px;
        width: 100px;
    }
    .user-img span {
        font-size: 25px;
    }

    .title {
        margin-bottom: 18px;
    }
    .title span {
        font-size: 18px;
    }
    .input-group{
        margin-bottom: 15px;
    }
    .btn-login button {
        margin-top: 30px;
    }
    
}
@media (max-width: 450px) and (min-width: 321px){
    .resetPassword .d-flex {
        padding: 0px;
    }
    .content {
        margin-top: 0px;
    }        
}